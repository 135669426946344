import AddIcon from '@mui/icons-material/Add';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { TextField, IconButton } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useState } from 'react';
import AgentList from "../components/AgentList";


const AgentExample = ({ IconProject, title }) => {
    return (
        <div className='flex justify-between items-center pl-[10px] pr-[10px] rounded-xl border-[1px] border-secondary-stroke w-[250px] h-[50px] bg-secondary-background'>
            <div className='flex justify-center items-center'>
                <div className='p-[5px] bg-secondary-ciel rounded-xl justify-between items-center mr-[10px] border-[1px] border-secondary-stroke'>
                    <IconProject className='text-primary-bleu' />
                </div>
                <p className='font-bold'>{title}</p>
            </div>
            <AddIcon className='text-secondary-stroke opacity-0' />
        </div>
    );
}

function Agent() {
    const [inputValue, setInputValue] = useState("");

    const handleSend = () => {
        console.log("Prompt envoyé : ", inputValue);
        setInputValue("");
    };

    return (
        <div className='p-[15px] h-screen flex flex-col'>
            <div className='flex-1 mt-[20px] pb-[20px] flex justify-between items-start border-2 rounded-lg bg-secondary-ciel'>
                <div className="pt-[80px] pb-[-80px] flex-1 mt-[20px] flex flex-col justify-center items-center space-y-6 ">
                    <div className='items-center justify-center'>
                        <h1 className="font-bold text-[35px] text-center">Créer un Agent</h1>
                        <p className="w-[350px] text-center font-regular text-secondary-raven">
                            Un agent est celui qui s'occupe de faire les recherches sur le web a notre place. 
                        </p>
                    </div>
                    <div className="flex justify-between items-center w-[530px]">
                        <div className="space-y-6">
                            <AgentExample IconProject={SpaOutlinedIcon} title={"Environnement"} />
                            <AgentExample IconProject={OfflineBoltOutlinedIcon} title={"Énergie"} />
                        </div>
                        <div className="space-y-6">
                            <AgentExample IconProject={SchoolOutlinedIcon} title={"Éducation"} />
                            <AgentExample IconProject={PaidOutlinedIcon} title={"Finance"} />
                        </div>
                    </div>
                    {/* Input */}
                    <div className="w-[530px] h-[50px] rounded-xl mt-auto flex justify-center items-center p-1 bg-secondary-white border-[1px]">
                        <TextField
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            variant="outlined"
                            placeholder="Creer un agent ..."
                            fullWidth
                            InputProps={{
                                style: {
                                    texte: 'black'
                                },
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent', 
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'white',
                                    },
                                },
                            }}
                            className="mr-2"
                        />
                        <div>
                            <IconButton className=' h-[30px] w-[30px]' onClick={handleSend}>
                                <AddCircleRoundedIcon className='text-secondary-raven'/>
                            </IconButton>
                        </div>
                    </div>
                </div>

                <AgentList />
            </div>
        </div>
    );
}

export default Agent;
