import { useState } from "react";
import CountryFliter from "./filters/CountryFilter";
import SectorFilter from "./filters/SectorFilter";
import SizeFilter from "./filters/SizeFilter";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';


function FilterNav({ step, next, prev }) {
    return (
        <div className="flex justify-around">
            {
                step !== 1 ?
                    <button onClick={prev} className="py-2 px-3 bg-primary-bleu rounded-lg text-secondary-white hover:bg-blue-800">
                        <ArrowBackRoundedIcon fontSize='small' /> Précédent
                    </button>
                    : null
            }

            {
                step !== 3 ?
                    <button onClick={next} className="py-2 px-3 bg-primary-bleu rounded-lg text-secondary-white hover:bg-blue-800">
                        Suivant <ArrowForwardRoundedIcon fontSize='small' />
                    </button>
                    : null
            }

            {
                step === 3 ?
                <button className="py-2 px-3 bg-primary-bleu rounded-lg text-secondary-white hover:bg-blue-800">
                    Confirmer <DoneRoundedIcon fontSize='small' />
                </button>
                : null
            }

        </div>
    );

}

function FilterPage() {
    const [step, setStep] = useState(1);

    const showFilter = (name) => {
        switch (name) {
            case 1:
                return <SectorFilter />
            case 2:
                return <SizeFilter />
            case 3:
                return <CountryFliter />
            default:
                return '404'
        }
    };

    const nextStep = () => {
        setStep(step + 1)
    }

    const previousStep = () => {
        setStep(step - 1)
    }

    return (
        <div className="h-screen flex justify-center items-center bg-secondary-stroke overflow-auto">
            <div>
                {showFilter(step)}

                <div className="mt-5">
                    <FilterNav step={step} next={nextStep} prev={previousStep} />
                </div>
            </div>
        </div>
    );
}

export default FilterPage;