import SearchInput from "../components/SearchInput";
import FilterDropdown from "../components/FilterDropdown";
import TableList from "../components/TableList";
import SEO from "../components/SEO";

// WEBSITE LIST PAGE
function Data() {
    return (
        <>
            <SEO title="Données | iTady" description="Données" />
            <div className="p-[15px] h-screen flex flex-col">
            <div className="flex-1 border-secondary-stroke border-2 rounded-lg bg-secondary-ciel mt-[20px] overflow-auto">
                <div className="flex justify-between items-baseline mr-[10px] ml-[34px] pr-[5px] pl-[5px] mt-[15px]">
                    <p className="font-bold text-[20px]">Liste des sites</p>
                    <div className="flex justify-center items-center">
                        <SearchInput />
                        <FilterDropdown />
                    </div>
                </div>
                <TableList repeatCount={10} />
            </div>
        </div>
        </>
    );
}

export default Data;
