import { Link, useNavigate } from "react-router-dom";
import { IconInput } from "../../components/inputs/IconInput";
import { PasswordInput } from "../../components/inputs/PasswordInput";
import { LoginImage } from "../../components/LoginImage";
import { LogoImage } from "../../components/LogoImage";
import SEO from "../../components/SEO";
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { loginUser } from "../../redux/actions/authActions";
import { Alert } from "../../components/alerts/Alert";

function LoginPage() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { loading, error, accessToken } = useSelector((state) => state.auth);
    
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(loginUser({ email, password }));
        console.log({ email, password });
    }

    const navigate = useNavigate();

    useEffect(() => {
        if (accessToken) {
          navigate('/workspace')
        }
      }, [navigate, accessToken])

    return (
        <>
            <SEO title="Login | iTady" description="Page de login" />
            <div className="h-screen w-screen flex items-center justify-between overflow-auto box-border">
                <div className="flex flex-col flex-grow justify-center items-center">
                    <form onSubmit={handleSubmit} className="mb-4">
                        <div className="mb-5">
                            <LogoImage />
                            <div className="text-4xl font-bold">Connectez-vous</div>
                            <div className="text-text">Bienvenu dans ITady 👋</div>
                        </div>
                        <IconInput
                            name="email"
                            placeholder="Votre email"
                            className="mb-5"
                            icon={<MailOutlineRoundedIcon fontSize="small" />}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <PasswordInput
                            name="password"
                            placeholder="Votre mot de passe"
                            className="mb-5"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />

                        <div className="flex justify-between gap-4 items-center mb-4">
                            <div className="flex items-center">
                                <input id="default-checkbox" type="checkbox" value="" className="text-blue" />
                                <label className="ms-1 text-sm font-medium text-secondary-pearl">Se souvenir de moi</label>
                            </div>
                            <div>
                                <a href={`test`} className="text-sm text-primary-bleu underline font-bold">Mot de passe oublié ?</a>
                            </div>
                        </div>

                        <div className="w-full">
                            <button className="p-4 bg-primary-bleu rounded-lg w-full text-white font-extrabold" disabled={loading}>
                                {loading ? '...' : 'Connexion'}
                            </button>
                        </div>

                        <div className='w-full'>
                            {error && <Alert type='error' message={error} />}
                        </div>
                    </form>

                    <div className="text-center">
                        <div className="text-text">Vous n'avez pas encore de compte ?</div>
                        <div className="text-sm text-primary-blue underline font-bold">
                            <Link to='/register'>S'inscrire</Link>
                        </div>
                    </div>
                </div>
                <div className="hidden lg:block bg-primary-bleu w-1/2 box-border">
                    <LoginImage />
                </div>
            </div>
        </>
    );
}

export default LoginPage;