import { useRef, useState } from "react";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

function MutliSelect({tags = [], placeholder="Search"}) {
    const [query, setQuery] = useState("");
    const [selected, setSelected] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);

    const inputRef = useRef(null);

    const filteredTags = tags.filter(
        (item) =>
            item?.toLocaleLowerCase()?.includes(query.toLocaleLowerCase()?.trim()) &&
            !selected.includes(item)
    );

    const isDisable =
        !query?.trim() ||
        selected.filter(
            (item) =>
                item?.toLocaleLowerCase()?.trim() === query?.toLocaleLowerCase()?.trim()
        )?.length;

    return (
        <div className="w-full flex flex-col relative">
            {selected?.length ? (
                <div className="w-full flex gap-2 justify-between">
                    <div className="flex gap-2 flex-wrap">
                        {selected.map((tag) => {
                            return (
                                <div key={tag} className="px-4 font-bold border-2 border-gray-600 flex items-center gap-2 py-2 rounded-full shadow-md bg-white">
                                    <span>{tag}</span>
                                    <button
                                        onMouseDown={(e) => e.preventDefault()}
                                        onClick={() =>
                                            setSelected(selected.filter((i) => i !== tag))
                                        }
                                    >
                                        <CloseRoundedIcon sx={{ fontSize: 15 }} />
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                    <div className="flex items-center">
                        <span
                            className="cursor-pointer text-gray-700"
                            onClick={() => {
                                setSelected([]);
                                inputRef.current?.focus();
                            }}
                        >
                         <DeleteForeverIcon />
                        </span>
                    </div>
                </div>
            ) : null}
            <div className="my-2 flex justify-between items-center pr-4 bg-white border border-secondary-raven rounded-lg outline-none">
                <input
                    ref={inputRef}
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value.trimStart())}
                    placeholder={placeholder}
                    className="w-full bg-transparent outline-none p-4"
                    onFocus={() => setMenuOpen(true)}
                    onBlur={() => setMenuOpen(false)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && !isDisable) {
                            setSelected((prev) => [...prev, query]);
                            setQuery("");
                            setMenuOpen(true);
                        }
                    }}
                />
                <SearchRoundedIcon />
                {/* <button
                    className=""
                    disabled={isDisable}
                    onClick={() => {
                        if (isDisable) {
                            return;
                        }
                        setSelected((prev) => [...prev, query]);
                        setQuery("");
                        inputRef.current?.focus();
                        setMenuOpen(true);
                    }}
                >
                    + Add
                </button> */}
            </div>

            {/* Menu's */}
            {menuOpen ? (
                <div className="w-full flex flex-col mt-1 relative shadow-md max-h-[200px] overflow-hidden p-3 gap-2 bg-white z-50 rounded-lg">
                    <ul className="h-full overflow-auto">
                        {filteredTags?.length ? (
                            filteredTags.map((tag, i) => (
                                <li
                                    key={tag}
                                    className="font-bold flex justify-between p-2 ps-4 text-gray-900 hover:bg-gray-200 rounded-md cursor-pointer"
                                    onMouseDown={(e) => e.preventDefault()}
                                    onClick={() => {
                                        setMenuOpen(true);
                                        setSelected((prev) => [...prev, tag]);
                                        setQuery("");
                                    }}
                                >
                                    {tag}
                                    <AddCircleRoundedIcon />
                                </li>
                            ))
                        ) : (
                            <li className="">......</li>
                        )}
                    </ul>
                </div>
            ) : null}
        </div>
    );
};

export default MutliSelect;