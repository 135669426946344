import { Outlet } from "react-router-dom";
import SideBar from "../components/SiderBar";

function Primary() {
    return (
        <div className="flex h-screen overflow-hidden">
            <SideBar />

            <div className="h-screen overflow-auto flex-grow">
                <Outlet />
            </div>
        </div>
    );
}

export default Primary;