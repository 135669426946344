export function Alert({ type = 'info', message }) {
    let textStyle = 'font-bold ';
    switch (type.toLowerCase()) {
        case 'success':
            textStyle += 'text-green-700';
            break;
        case 'error':
            textStyle += 'text-red-700';
            break;
        default:
            textStyle += 'text-yellow-700'
    }
    return (
        <div className="w-full m-2 text-red">
            <span className={textStyle}>{message}</span>
        </div>
    );
}