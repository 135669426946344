import { Link } from "react-router-dom";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import { useState } from "react";

function InviteMembers() {

    const [members, setMembers] = useState([]);
    const [email, setEmail] = useState('');

    const invite = (e) => {
        e.preventDefault();
        if (email.trim()) {
            setMembers([...members, email]);
            setEmail('');
        }
    };

    const removeMember = (email) => {
        setMembers(members.filter((m) => m !== email));
    };

    return (
        <div className="min-h-screen flex flex-col bg-white text-primary-bleu">
            <header className="py-6 px-4 sm:px-6 lg:px-8 bg-gray-100">
                <div className="max-w-7xl mx-auto flex justify-between items-center">
                    <Link to={`/workspace/1`} className="text-primary-bleu hover:text-primary-jaune transition-colors font-extrabold">
                        <ArrowBackRoundedIcon />
                        <span className="underline"> Retour</span>
                    </Link>
                </div>
            </header>

            <main className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <h2 className="text-3xl font-bold mb-8 text-center text-primary-bleu">
                    Participants
                </h2>
                <div className="flex justify-center">
                    <form onSubmit={invite} className="w-[700px] max-w-[700px]">
                        <div className="mb-5">
                            <label className="font-extrabold mb-2">Inviter des participants</label>

                            <div class="relative">
                                <div class="absolute inset-y-0 start-0 flex items-center text-secondary-raven ps-3 pointer-events-none">
                                    <AlternateEmailRoundedIcon />
                                </div>
                                <input
                                    type="email"
                                    id="search"
                                    class="block w-full p-4 ps-10 text-sm text-secondary-pearl border border-gray-300 rounded-lg focus:outline-none focus:ring-2 bg-gray-50 focus:ring-primary-bleu"
                                    placeholder="Entrer l'email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <button type="submit" class="text-white absolute end-2.5 bottom-2.5 bg-primary-jaune hover:bg-primary-jaune/80 font-medium rounded-lg text-sm px-4 py-2">Inviter</button>
                            </div>
                        </div>
                        <div>
                            <label className="font-extrabold mb-2">Participants</label>
                            <div className="p-2 flex flex-col gap-2">
                                {
                                    members.map((email) => (
                                        <div key={email} className="flex justify-between p-3 border border-secondary-stroke rounded-md">
                                            <div>{email}</div>
                                            <button type="button" onClick={() => removeMember(email)}><HighlightOffIcon /></button>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </main>

            <footer className="py-6 px-4 sm:px-6 lg:px-8 text-center bg-gray-100">
                <p className="text-primary-bleu/60">
                    © 2024 AKATA GOAVANA. All rights reserved.
                </p>
            </footer>
        </div>
    );
}

export default InviteMembers;