import axios from "axios";
import { getAccessToken } from "./helpers";

const apiClient = axios.create({
	baseURL: 'http://localhost:5000/api',
	headers: {
		'Content-Type': 'application/json',
	},
});

// Request interceptors
apiClient.interceptors.request.use(
	config => {
		// Add authorization token to every request
		const token = getAccessToken();
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	error => Promise.reject(error)
);
// Response interceptor
apiClient.interceptors.response.use(
	response => response,
	error => {
	  console.error('API call failed:', error);
	}
  );

export default apiClient;