import MutliSelect from '../../components/inputs/MultiSelect';

function CountryFliter() {
    const countries = [
        "Madagascar",
        "France",
        "Canada",
        "Etats-Unis"
    ]

    return (
        <div>
            <div className="text-center mb-10">
                <h1 className="text-3xl font-extrabold">Dans quel pays vous voulez trouver votre site ?</h1>
                <h3>Sélectionnez le pays où vous aimeriez localiser votre site</h3>
            </div>
            <div className="flex justify-center">
                <div className="w-[500px] max-w-[500px]">
                    <MutliSelect tags={countries} placeholder="Rechercher un pays" />
                </div>
            </div>
        </div>
    );
}

export default CountryFliter;