import React, { useState } from 'react';
import { TextField, IconButton, Box, Modal } from '@mui/material';
import { Close } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';

const AgentList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };


  return (
    <div className="max-w-sm mx-auto p-4 rounded-lg relative">
      {/* Menu Toggle */}
      <div className="flex justify-end">
        <IconButton onClick={toggleSection}>
          {isOpen ? <Close /> : <MenuIcon />}
        </IconButton>
      </div>

      {/* Agent List */}
      <div
        className={`duration-500 transform ${
          isOpen ? 'translate-x-0 w-full opacity-100' : 'translate-x-full w-0 opacity-0'
        } overflow-hidden`}
        style={{ transitionProperty: 'transform, width, opacity' }}
      >
        {isOpen && (
          <h2 className="text-xl font-bold mb-4 transition-opacity duration-500">
            Agent (17)
          </h2>
        )}

        <div className="bg-secondary-white p-4 rounded-lg">
          <div className="mb-4">
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Chercher"
              size="small"
              className="rounded-lg"
            />
          </div>

          <div className="space-y-4">
            {Array.from({ length: 4 }).map((_, index) => (
              <div
                key={index}
                onClick={handleOpenModal} // Open the modal when any agent is clicked
                className="bg-secondary-white border rounded-lg p-4 hover:bg-secondary-background transition duration-300 ease-in-out cursor-pointer"
              >
                <h4 className="font-bold text-[14px]">Agent numero {index + 1}</h4>
                <p className="text-sm text-secondary-raven">
                  L'agent gere et optimise les offres e-commerce pour maximiser les ventes er ameliorer l'experiences clients.
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          className="bg-secondary-white max-w-lg w-full mx-auto p-6 rounded-lg shadow-lg relative"
          style={{ top: '50%', transform: 'translateY(-50%)' }}
        >
          {/* Modal Header */}
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <span className="font-semibold">Paramètre des agents</span>
            </div>
            <IconButton onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </div>
          <h2 className="text-[20px] font-bold mb-4">Agent numéro un</h2>
          <div className="space-y-4">
            <div>
              <label className="block font-semibold mb-1">Nom</label>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="John Doe"
                size="small"
                className="rounded-lg"
                />
            </div>

            <div>
              <label className="block font-semibold mb-1">Type</label>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Technologie"
                size="small"
                className="rounded-lg"
                />
            </div>
          </div>

          {/* Footer Buttons */}
          <div className="flex justify-between items-center mt-6">
            <button
              onClick={handleCloseModal}
              className="bg-secondary-white text-secondary-pearl font-bold mt-4 px-4 py-2 rounded-xl hover:bg-secondary-background"
            >
              Annuler
            </button>
            <button className="bg-primary-bleu text-secondary-white font-bold mt-4 px-4 py-2 rounded-xl hover:bg-primary-bleu">
              Ajouter
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AgentList;
