import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';

//SOCIETY SIZE
function MyCheckBox ({ label }) {
  const [selectedValue, setSelectedValue] = useState(false);

  const handleChange = () => {
    setSelectedValue(prev => !prev);
  };

  return (
    <div
      className={`w-full h-full flex items-center justify-start p-3 rounded-lg
        ${selectedValue ? 'border-primary-bleu bg-secondary-stroke' : 'border-secondary-stroke'} 
        border-2 bg-secondary-white`}
    >
      <Checkbox
        checked={selectedValue}
        onClick={handleChange}
        value="selected"
        name="custom-checkbox"
        inputProps={{ 'aria-label': 'Custom Checkbox' }}
        sx={{
          color: 'lightgray',
          '&.Mui-checked': {
            color: '#001751',
          },
          '& .MuiSvgIcon-root': {
            borderRadius: 0, 
          },
        }}
      />
      <Typography variant="body1" className={`${selectedValue ? 'text-[#001751] font-bold' : 'text-black'} ml-2`}>
        {label}
      </Typography>
    </div>
  );
};

export default MyCheckBox;
