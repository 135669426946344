
export function MyInput({className, ...props }) {
    const defaultStyle = 'p-4 border border-secondary-raven outline-none focus:ring-2 focus:border-transparent focus:ring-primary-bleu w-full rounded-lg';
    return (
        <div>
            <input
                type="text"
                name=""
                id=""
                className={`${defaultStyle} ${className}`}
                {...props}
            />
        </div>
    );
}