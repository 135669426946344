import MyCheckBox from "../../components/inputs/MyCheckbox";

function SizeFilter() {
    return (
        <div>
            <div className="text-center mb-5">
                <h1 className="text-3xl font-extrabold">Veuillez sélectionner la taille de l’entreprise</h1>
                <h3>Choisissez la catégorie qui correspond le mieux à la taille de l’entreprise que vous ciblez.</h3>
            </div>
            <div className="flex justify-center">
                <div className="w-[500px] max-w-[500px]">
                    <div className="flex flex-col justify-center items-center gap-2">
                        <MyCheckBox label="Petit" />
                        <MyCheckBox label="Moyenne" />
                        <MyCheckBox label="Grand" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SizeFilter;