import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import akata from '../assets/images/akata.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import CloseIcon from '@mui/icons-material/Close';

//NOTIFICATION COMPONENT
function NotificationBar() {
  const [isModalOpen, setModalOpen] = useState(false);
  
  const openModal = () => {
    setModalOpen(true);
  };
  
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <div
        className='bg-secondary-white hover:bg-secondary-background flex p-3 justify-between items-center border-b border-secondary-stroke'
        onClick={openModal}
      >
        <div className='flex justify-start items-center'>
          <img src={akata} alt=' ' className='h-[35px] w-[35px]' />
          <div className='p-2 '>
            <p className='font-bold'>
              <b>AKATA GOAVANA</b> a ajouter un offre
            </p>
            <p className='text-sm text-gray-500'>30 min · Développeur</p>
          </div>
        </div>
        <div className='h-[10px] w-[10px] rounded-full bg-primary-jaune'></div>
      </div>

      {/* MODAL */}
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        className='flex justify-center items-center'
      >
        <div className='bg-secondary-white w-[600px] p-8 rounded-lg shadow-lg relative'>
          <div className='flex justify-between items-start'>
            <div className='flex justify-start items-center'>
              <img src={akata} alt='' className='h-[45px] w-[45px]' />
              <div className='ml-[20px]'>
                <h2 className='text-xl font-bold'>AKATA GOAVANA</h2>
                <h3 className='text-md font-semibold text-gray-600'>
                  Digitalisation d’un Processus de Gestion de Rendez-vous
                </h3>
              </div>
            </div>
            <div
              className='p-[5px] rounded-xl bg-secondary-background cursor-pointer'
              onClick={closeModal}
            >
              <CloseIcon className='h-[15px] w-[15px] text-secondary-raven' />
            </div>
          </div>

          <div className='pt-[20px]'>
            <p className='mt-4'>
              Notre entreprise est à la recherche d'une Entreprise de Services Numériques (ESN) capable de nous accompagner dans
              l'optimisation et la digitalisation de notre processus de gestion des rendez-vous. Nous souhaitons moderniser nos
              outils actuels afin d'améliorer l’efficacité, la flexibilité, et l'expérience utilisateur, tant pour nos clients que
              pour notre personnel interne.
            </p>
            <p className='mt-4'>
              <strong>Contexte et Objectifs :</strong>
              <br />
              Actuellement, notre gestion des rendez-vous repose en grande partie sur des processus manuels et des outils
              disparates qui manquent d’interconnexion. Cela entraîne des inefficacités, des erreurs de planification, et une
              expérience client moins fluide que ce que nous désirons offrir.
            </p>
            <p className='mt-4'>
              <strong>Objectifs de la digitalisation :</strong>
              <br />
              Centralisation, Automatisation, Accessibilité, Intégration, Analyse des données.
            </p>
          </div>

          <div className='flex justify-between items-end pt-[20px]'>
            <div className='flex justify-center items-center'>
              <LinkedInIcon className='h-[10px] w-[10px] ml-[5px] text-secondary-raven' />
              <EmailIcon className='h-[10px] w-[10px] ml-[5px] text-secondary-raven' />
              <FacebookOutlinedIcon className='h-[10px] w-[10px] ml-[5px] text-secondary-raven' />
              <TwitterIcon className='h-[10px] w-[10px] ml-[5px] text-secondary-raven' />
            </div>
            <button className='bg-primary-bleu text-secondary-white font-bold mt-4 px-4 py-2 rounded-xl hover:bg-primary-bleu'>
              Contacter
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default NotificationBar;
