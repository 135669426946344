import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../redux/authSlice";

function Workspaces() {
    const workspaces = [
        { id: 1, name: "Personal", members: 1 },
        { id: 2, name: "Team Alpha", members: 5 },
        { id: 3, name: "Project X", members: 3 },
        { id: 4, name: "Marketing", members: 8 },
    ]

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout());
        navigate('/login');
    }

    return (
        <div className="min-h-screen flex flex-col bg-white text-secondary-white">
            <header className="py-6 px-4 sm:px-6 lg:px-8 bg-primary-bleu">
                <div className="max-w-7xl mx-auto flex justify-between items-center">
                    <h1 className="text-2xl font-bold text-secondary-white">
                        <strong className="text-primary-jaune">IT</strong>ady
                    </h1>
                    <button onClick={handleLogout} className="text-secondary-white hover:text-primary-jaune transition-colors font-extrabold">
                        Se déconnecter
                    </button>
                </div>
            </header>

            <main className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <h2 className="text-3xl font-bold mb-8 text-center text-primary-bleu">
                    Choisir un espace de travail
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {workspaces.map((workspace) => (
                        <Link to={`${workspace.id}`}
                            key={workspace.id}
                            className="bg-white rounded-lg p-6 hover:shadow-lg transition-shadow cursor-pointer border-2 border-gray-200"
                        >
                            <h3 className="w-full text-xl font-semibold mb-2 text-primary-bleu">
                                <span>{workspace.name}</span>
                            </h3>
                            <p className="text-primary-jaune font-medium">
                                {workspace.members} participant{workspace.members !== 1 && "s"}
                            </p>
                        </Link>
                    ))}
                    <div className="bg-primary-bleu text-white rounded-lg p-6 shadow-md hover:bg-primary-bleu/90 transition-colors cursor-pointer flex items-center justify-center">
                        <Link to={`/create-workspace`}>
                            <span className="text-xl font-semibold">+ Créer un workspace</span>
                        </Link>
                    </div>
                </div>
            </main>

            <footer className="py-6 px-4 sm:px-6 lg:px-8 text-center bg-gray-100">
                <p className="text-primary-bleu/60">
                    © 2024 AKATA GOAVANA. All rights reserved.
                </p>
            </footer>
        </div>
    )
}

export default Workspaces;