import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Primary from "./pages/Primary";
import Menu from "./pages/Menu";
import Workspaces from "./pages/workspace/Workspaces";
import Agent from "./pages/Agent";
import LoginPage from "./pages/auth/LoginPage";
import { RegisterPage } from "./pages/auth/RegisterPage";
import Data from "./pages/Data";
import ClientList from "./pages/ClientList";
import FilterPage from "./pages/FilterPage";
import ContactList from "./pages/ContactList";
import CreateWorkspace from "./pages/workspace/CreateWorkspace";
import ProtectedRoute from "./components/routing/ProtectedRoute";
import InviteMembers from "./pages/workspace/InviteMembers";
import NotFound from "./components/routing/NotFound";

function Main() {

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to={`workspace`} replace />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path='/register' element={<RegisterPage />} />
                <Route path='/create-workspace' element={<CreateWorkspace />} />
                <Route element={<ProtectedRoute />}>
                    <Route path='/workspace' element={<Workspaces />} />
                    <Route path="/workspace/:id" element={<Primary />}>
                        <Route path="" element={<Menu />} />
                        <Route path="agent" element={<Agent />} />
                        <Route path="donnees" element={<Data />} />
                        <Route path="clients" element={<ClientList />} />
                        <Route path="filtres" element={<FilterPage />} />
                        <Route path="contacts" element={<ContactList />} />
                        <Route path="invite" element={<InviteMembers />} />
                    </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
}

export default Main;