import MutliSelect from '../../components/inputs/MultiSelect';

export default function SectorFilter () {
    const sectors = [
        "Services financiers",
        "Santé",
        "Restauration",
        "Education",
        "Développement",
        "Innovation",
        "Télécommunications",
        "Industrie manufacturière"
    ];

    return (
        <div>
            <div className="text-center mb-10">
                <h1 className="text-3xl font-extrabold">Dans quel secteur vous voulez trouver votre site ?</h1>
                <h3>Indiquez le secteur d'activité dans lequel vous souhaitez établir ou rechercher un site. </h3>
            </div>
            <div className="flex justify-center">
                <div className="w-[500px] max-w-[500px]">
                    <MutliSelect tags={sectors} placeholder="Rechercher un secteur" />
                </div>
            </div>
        </div>
    );
}