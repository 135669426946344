import { IconInput } from '../../components/inputs/IconInput';
import { PasswordInput } from '../../components/inputs/PasswordInput';
import { LoginImage } from '../../components/LoginImage';
import { LogoImage } from '../../components/LogoImage';
import SEO from '../../components/SEO';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { registerUser } from '../../redux/actions/authActions';
import { Alert } from '../../components/alerts/Alert';

export function RegisterPage() {

	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	// Importation du state globale
	const { loading, error } = useSelector(
		(state) => state.auth
	);

	// Importation du dispach
	const dispatch = useDispatch();

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(registerUser({firstname, lastname, email, password}));
		console.log({firstname, lastname, email, password});
	}


	return (
		<>
			<SEO title="Inscription | iTady" description="Page d'inscription" />
			<div className="h-screen w-screen flex items-center justify-between overflow-auto box-border">
				<div className="flex flex-col flex-grow justify-center items-center">
					<form onSubmit={handleSubmit} className="mb-4">
						<div className="mb-5">
							<LogoImage />
							<div className="text-4xl font-bold">Inscrivez-vous </div>
							<div className="text-text">Bienvenu dans ITady 👋</div>
						</div>

						<IconInput
							icon={<PersonOutlineOutlinedIcon
								fontSize='small' />}
							name="firstname"
							placeholder="Votre prénom"
							className="mb-5"
							onChange={(e) => setFirstname(e.target.value)}
							required
						/>

						<IconInput
							icon={<PersonOutlineOutlinedIcon
								fontSize='small' />}
							name="lastname"
							placeholder="Votre nom"
							className="mb-5"
							onChange={(e) => setLastname(e.target.value)}
							required
						/>

						<IconInput
							type='email'
							icon={<MailOutlinedIcon
								fontSize='small' />}
							name="email"
							placeholder="Votre email"
							className="mb-5"
							onChange={(e) => setEmail(e.target.value)}	
							required
						/>

						<PasswordInput
							name="password"
							placeholder="Votre mot de passe"
							className="mb-5"
							onChange={(e) => setPassword(e.target.value)}
							required
						/>

						<div className="w-full">
							<button className="p-4 bg-primary-bleu rounded-lg w-full text-white font-extrabold" disabled={loading}>
								{loading ? '...': 'Suivant'}
							</button>
						</div>

						<div className='w-full'>
							{error && <Alert type='error' message={error} />}
						</div>
					</form>

					<div className="text-center">
						<div className="text-secondary-pearl">Vous avez déjà un compte ?</div>
						<div className="text-sm text-primary-bleu underline font-bold">
							<Link to='/login'>Se connecter</Link>
						</div>
					</div>
				</div>
				<div className="hidden lg:block bg-primary-bleu h-full w-1/2">
					<LoginImage />
				</div>
			</div>
		</>
	);
}